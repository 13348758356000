var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pa-3"
  }, [_vm.company.remainingLimit && _vm.company.remainingLimit.amount < 1 ? _c('v-alert', {
    staticClass: "my-2",
    attrs: {
      "type": "info",
      "dense": "",
      "color": "danger"
    }
  }, [_vm._v(" The current company has no remaining balance. ")]) : _vm._e(), _c('v-row', [_c('v-col', {
    staticClass: "d-flex flex-column justify-space-between",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', [_c('h4', {
    staticClass: "mt-2 ml-1 primary--text"
  }, [_vm._v("Company Information")]), _c('v-card', {
    staticClass: "d-flex my-1 pa-3 borderccc"
  }, [_c('v-row', {
    staticClass: "pt-1 ma-0"
  }, [_c('v-col', {
    staticClass: "d-flex flex-column justify-space-between pa-0 ma-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_c('span', {
    staticClass: "info-title"
  }, [_vm._v("Company Name")]), _c('span', {
    staticClass: "info-text"
  }, [_vm._v(_vm._s(_vm.company.name ? _vm.company.name : "---"))])]), _c('div', [_c('span', {
    staticClass: "info-title"
  }, [_vm._v("Organization Number")]), _c('span', {
    staticClass: "info-text"
  }, [_vm._v(_vm._s(_vm.company.organizationNumber ? _vm.company.organizationNumber : "---"))])])]), _c('v-col', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', {
    staticClass: "info-title"
  }, [_vm._v("Tenants")]), _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, _vm._l(_vm.filteredTenantNames, function (tenant, idx) {
    return _c('span', {
      key: idx,
      staticClass: "info-text d-inline-block"
    }, [_vm._v(" " + _vm._s(_vm.company.tenants ? tenant : "---")), idx < _vm.filteredTenantNames.length - 1 ? _c('span', [_vm._v(", ")]) : _vm._e()]);
  }), 0)])], 1)], 1)], 1), _c('div', [_c('h4', {
    staticClass: "mt-2 ml-1 primary--text"
  }, [_vm._v("Contact Person")]), _c('v-card', {
    staticClass: "my-1 pa-3 borderccc"
  }, [_c('v-row', {
    staticClass: "pt-1 ma-0"
  }, [_c('v-col', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('span', {
    staticClass: "info-title"
  }, [_vm._v("Full Name")]), _c('span', {
    staticClass: "info-text"
  }, [_vm._v(_vm._s(_vm.company.contactPersonName ? _vm.company.contactPersonName : "---"))])]), _c('v-col', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('span', {
    staticClass: "info-title"
  }, [_vm._v("Phone Number")]), _c('span', {
    staticClass: "info-text"
  }, [_vm._v(_vm._s(_vm.company.phone ? _vm.company.phone : "---"))])])], 1), _c('v-row', {
    staticClass: "pt-1 ma-0"
  }, [_c('v-col', {
    staticClass: "pa-0 ma-0"
  }, [_c('span', {
    staticClass: "info-title"
  }, [_vm._v("E-Mail")]), _c('span', {
    staticClass: "info-text"
  }, [_vm._v(_vm._s(_vm.company.email ? _vm.company.email : "---"))])])], 1)], 1)], 1)]), _c('v-col', {
    staticClass: "d-flex flex-column justify-space-between",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', [_c('h4', {
    staticClass: "mt-2 ml-1 primary--text"
  }, [_vm._v("Reference")]), _c('v-card', {
    staticClass: "my-1 pa-3 borderccc"
  }, [_c('v-row', {
    staticClass: "pt-1 ma-0"
  }, [_c('v-col', {
    staticClass: "pa-0 ma-0"
  }, [_c('span', {
    staticClass: "info-title"
  }, [_vm._v("E-Mail for Invoice or E-Invoice")]), _c('span', {
    staticClass: "info-text"
  }, [_vm._v(_vm._s(_vm.company.invoiceEmail ? _vm.company.invoiceEmail : "---"))])])], 1), _c('v-row', {
    staticClass: "pt-1 ma-0"
  }, [_c('v-col', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('span', {
    staticClass: "info-title"
  }, [_vm._v("Other / Branch")]), _c('span', {
    staticClass: "info-text"
  }, [_vm._v(_vm._s(_vm.company.reference ? _vm.company.reference : "---"))])]), _c('v-col', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('span', {
    staticClass: "info-title"
  }, [_vm._v("Fortnox Number")]), _c('span', {
    staticClass: "info-text"
  }, [_vm._v(_vm._s(_vm.company.fortnoxNumber ? _vm.company.fortnoxNumber : "---"))])])], 1)], 1)], 1), _c('div', [_c('h4', {
    staticClass: "mt-2 ml-1 primary--text"
  }, [_vm._v("Finance")]), _c('v-card', {
    staticClass: "my-1 pa-3 borderccc"
  }, [_c('v-row', {
    staticClass: "pt-1 ma-0",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-col', {
    staticClass: "pa-0 ma-0"
  }, [_c('span', {
    staticClass: "info-title"
  }, [_vm._v("Allowed Days to Pay the Invoice")]), _c('span', {
    staticClass: "info-text"
  }, [_vm._v(_vm._s(_vm.company.allowedDaysToPay ? _vm.company.allowedDaysToPay : "---"))])]), _c('v-col', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('span', {
    staticClass: "info-title"
  }, [_vm._v("Limit")]), _c('span', {
    staticClass: "info-text"
  }, [_vm._v(_vm._s(_vm.company.limit.amount ? _vm.company.limit.plainText : "---"))])])], 1), _c('v-row', {
    staticClass: "pt-1 ma-0"
  }, [_c('v-col', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('span', {
    staticClass: "info-title"
  }, [_vm._v("Automatically Add Invoice Fee")]), _c('span', {
    staticClass: "info-text"
  }, [_vm._v(_vm._s(_vm.company.automaticInvoiceFeeEnabled ? "YES" : "NO"))])]), _c('v-col', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('span', {
    staticClass: "info-title",
    class: _vm.company.remainingLimit && _vm.company.remainingLimit.amount != 0 ? 'greeny--text' : 'primary--text'
  }, [_vm._v("Remaning Limit")]), _c('span', {
    staticClass: "info-text"
  }, [_vm._v(_vm._s(_vm.company.remainingLimit && _vm.company.remainingLimit.amount ? _vm.company.remainingLimit.plainText : "---"))])])], 1)], 1)], 1)])], 1), _vm.company && _vm.company.comment ? _c('v-card', {
    staticClass: "my-2 borderccc py-3",
    staticStyle: {
      "background-color": "#fff6d9"
    }
  }, [_c('v-row', [_c('v-col', [_c('v-card-title', {
    staticClass: "black--text mt-0 pt-0"
  }, [_c('span', {
    staticClass: "text-sm-body-2 font-weight-bold"
  }, [_vm._v("Internal Message")])]), _c('v-card-subtitle', {
    staticClass: "black--text mb-0 pb-0"
  }, [_vm._v(" " + _vm._s(_vm.company.comment))])], 1)], 1)], 1) : _vm._e(), _c('h4', {
    staticClass: "mt-6 ml-1 primary--text"
  }, [_vm._v("Authorized Employee")]), _c('div', {
    staticClass: "text-center"
  }, [_vm.loading ? _c('v-progress-circular', {
    staticClass: "mt-2",
    attrs: {
      "size": 50,
      "color": "primary",
      "indeterminate": ""
    }
  }) : _vm._e()], 1), !_vm.loading ? _c('div', {
    staticStyle: {
      "padding": "2px"
    }
  }, _vm._l(_vm.company.customers, function (item, index) {
    return _c('v-card', {
      key: index,
      staticClass: "my-2 pa-3 borderccc"
    }, [_c('v-row', {
      staticClass: "pt-0 ma-0"
    }, [_c('v-col', {
      staticClass: "pa-0 ma-0"
    }, [_c('v-icon', {
      attrs: {
        "dense": "",
        "aria-hidden": "false",
        "color": "accent"
      }
    }, [_vm._v(" mdi-pound ")]), _c('span', {
      staticClass: "accent--text font-weight-bold"
    }, [_vm._v("IATA Number")]), item.companyCustomerNumber ? _c('span', {
      staticClass: "ml-2 font-weight-bold"
    }, [_vm._v(" " + _vm._s(item.companyCustomerNumber) + " ")]) : _c('span', {
      staticClass: "ml-2 font-weight-bold"
    }, [_vm._v("----")])], 1), _c('v-col', {
      staticClass: "pa-0 ma-0"
    }, [_c('v-icon', {
      attrs: {
        "dense": "",
        "aria-hidden": "false",
        "color": "accent"
      }
    }, [_vm._v(" mdi-account ")]), item.firstName || item.lastName ? _c('span', {
      staticClass: "font-weight-bold"
    }, [_vm._v(" " + _vm._s(item.firstName + " " + item.lastName) + " ")]) : _c('span', {
      staticClass: "ml-2 font-weight-bold"
    }, [_vm._v("----")])], 1), _c('v-col', {
      staticClass: "pa-0 ma-0"
    }, [_c('v-icon', {
      attrs: {
        "dense": "",
        "aria-hidden": "false",
        "color": "accent"
      }
    }, [_vm._v(" mdi-email ")]), item.email ? _c('span', {
      staticClass: "ml-2 font-weight-bold"
    }, [_vm._v(_vm._s(item.email))]) : _c('span', {
      staticClass: "ml-2 font-weight-bold"
    }, [_vm._v("----")])], 1), _c('v-col', {
      staticClass: "pa-0 ma-0 col-2"
    }, [_c('v-icon', {
      attrs: {
        "dense": "",
        "aria-hidden": "false",
        "color": "accent"
      }
    }, [_vm._v(" mdi-phone ")]), item.phone ? _c('span', {
      staticClass: "ml-2 font-weight-bold"
    }, [_vm._v(_vm._s(item.phone))]) : _c('span', {
      staticClass: "ml-2 font-weight-bold"
    }, [_vm._v("----")])], 1), _c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-icon', _vm._g(_vm._b({
            attrs: {
              "dense": "",
              "aria-hidden": "false",
              "color": "accent"
            }
          }, 'v-icon', attrs, false), on), [_vm._v("mdi-information-outline")])];
        }
      }], null, true)
    }, [item.reference ? _c('span', [_vm._v(_vm._s(item.reference))]) : _c('span', [_vm._v("No reference provided")])])], 1)], 1);
  }), 1) : _vm._e(), _c('h4', {
    staticClass: "black--text mt-6 mb-2",
    staticStyle: {
      "margin-left": "4px"
    }
  }, [_c('span', {
    staticClass: "mango-red"
  }, [_vm._v("Activity log ")]), _vm._v("(" + _vm._s(_vm.company.events.length) + ") ")]), _c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_c('v-card', {
    staticStyle: {
      "margin-left": "2px",
      "margin-right": "2px"
    }
  }, [_c('v-card-text', {
    staticClass: "elevation-1"
  }, [_c('v-timeline', {
    attrs: {
      "dense": "",
      "align-top": ""
    }
  }, [_c('event-list', {
    attrs: {
      "events": _vm.company.events
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }