export default {
  // Creating
  ORDER_CREATED: "success",
  ORDER_ITEM_ADDED: "success",

  // Removing
  ORDER_ITEM_REMOVED: "secondary",
  ORDER_EXPIRED: "secondary",
  ORDER_ANONYMIZED: "secondary",
  ORDER_ARCHIVED: "secondary",

  // Updating
  ORDER_UPDATED: "amber",
  ORDER_VALIDATED: "amber",
  ORDER_TENANT_CHANGED: "amber",
  ORDER_PURCHASE: "amber",
  ORDER_PRINTED: "amber",
  ORDER_UNARCHIVED: "amber",

  // Locking & Unlocking
  ORDER_LOCKED: "#34495e",
  ORDER_LOCKED_FOR_PRINTING: "#34495e",
  ORDER_UNLOCKED_FOR_PRINTING: "#34495e",
  ORDER_ITEM_LOCKED_FOR_REMOVAL: "#34495e",
  ORDER_ITEM_UNLOCKED_FOR_REMOVAL: "#34495e",

  // Sending E-mails
  ORDER_RAILPASS_ACTIVATION_EMAIL_SENT: "accent",
  ORDER_PRINTED_EMAIL_SENT: "accent",
  ORDER_REFUNDED_EMAIL_SENT: "accent",
  ORDER_EXPIRED_MAIL_SENT: "accent",
  ORDER_CONFIRMATION_SENT: "accent",
  ORDER_TICKETS_SENT: "accent",
  ORDER_RECEIPTS_SENT: "accent",
  LOGIN_2FA_CODE_SENT: "accent",

  // Cancelling
  ORDER_CANCELLED: "danger",
  ORDER_SHIPMENT_CANCELLED: "danger",

  // Refunding
  ORDER_REFUNDED: "warning",
  ORDER_REFUNDED_SPECIAL: "warning",

  // Other
  COMMENT: "grey lighten-1",
  ORDER_RECEIPTS_PROVIDED: "grey lighten-1",
  LOGIN_SUCCESS: "grey lighten-1",
};
