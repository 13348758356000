var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-slide-x-transition', {
    attrs: {
      "group": ""
    }
  }, _vm._l(_vm.events, function (event, i) {
    return _c('v-timeline-item', {
      key: i,
      staticClass: "mb-4",
      attrs: {
        "color": "accent",
        "small": ""
      }
    }, [!event.deleted || _vm.isBackOfficeAdmin ? _c('v-row', {
      staticStyle: {
        "border-bottom": "1px solid #dfdfdf"
      },
      attrs: {
        "justify": "space-between"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "7"
      }
    }, [_c('div', {
      staticClass: "mb-2"
    }, [_c('span', [_vm._v(_vm._s(_vm._f("getOnlyDate")(_vm.displayDate(event))))]), _c('span', [_vm._v(" " + _vm._s(_vm._f("getOnlyTime")(_vm.displayDate(event))) + " "), _c('small', [_vm._v("(GMT+" + _vm._s(_vm.getGmtDiff) + ")")])])]), _c('v-chip', {
      staticClass: "ml-0 mr-2 font-weight-medium",
      attrs: {
        "color": _vm.getChipColor(_vm.displayType(event)),
        "dark": "",
        "label": "",
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.displayType(event).replace(/_/g, " ")) + " ")]), _vm._v(" " + _vm._s(event.user != null ? event.user.name : "N/A") + " ")], 1), _vm.isBackOfficeAdmin && _vm.inquiryId ? _c('v-col', {
      staticClass: "text-right",
      attrs: {
        "cols": "5"
      }
    }, [_c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function ($event) {
          _vm.editItem = Object.assign({}, event);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "third"
      }
    }, [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.deleteInquiry(event.id);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "primary"
      }
    }, [_vm._v("mdi-delete")])], 1)], 1) : _vm._e(), Number.isInteger(event.refundAmount) || event.referencedBookingNumber ? _c('v-col', {
      staticClass: "pt-0 text--primary",
      attrs: {
        "cols": "12"
      }
    }, [_c('b', [_vm._v("Amount of Refund: " + _vm._s(event.refundAmount))]), event.referencedBookingNumber ? _c('h5', {
      staticClass: "grey--text text--darken-4 minw100"
    }, [_c('v-tooltip', {
      attrs: {
        "top": "",
        "open-delay": 10,
        "close-delay": 5
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_vm._v(" Referenced Booking Number: "), _c('v-icon', _vm._g(_vm._b({
            attrs: {
              "color": "mango-red",
              "small": ""
            },
            on: {
              "click": function ($event) {
                return _vm.copyToClipboard(event.referencedBookingNumber);
              }
            }
          }, 'v-icon', attrs, false), on), [_vm._v(" mdi-content-copy ")])];
        }
      }], null, true)
    }, [_c('span', [_vm._v("Copy")])]), _c('span', {
      staticClass: "ml-1 font-weight-bold"
    }, [_vm._v(_vm._s(event.referencedBookingNumber))])], 1) : _vm._e()]) : _vm._e(), event.message || event.description ? _c('v-col', {
      staticClass: "pt-0 text--primary",
      attrs: {
        "cols": "12"
      },
      domProps: {
        "innerHTML": _vm._s(_vm.displayDescription(event).split('\n').join('<br>'))
      }
    }) : _vm._e()], 1) : _vm._e()], 1);
  }), 1), _vm.editItem ? _c('v-dialog', {
    attrs: {
      "max-width": "700px",
      "persistent": ""
    },
    on: {
      "click:outside": function ($event) {
        _vm.editItem = null;
      }
    },
    model: {
      value: _vm.editItem,
      callback: function ($$v) {
        _vm.editItem = $$v;
      },
      expression: "editItem"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "title",
    attrs: {
      "fixed": "",
      "color": "titlebg",
      "dark": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("Edit Event")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "align-right",
    attrs: {
      "icon": "",
      "dark": ""
    },
    on: {
      "click": function ($event) {
        _vm.editItem = null;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('div', {
    staticClass: "content"
  }, [[_c('v-container', [_c('ValidationObserver', {
    ref: "upEventObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var Validate = _ref2.Validate,
          reset = _ref2.reset;
        return [_vm.eventStatus ? _c('select', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.editItem.code,
            expression: "editItem.code"
          }],
          staticClass: "pl-2 py-1 mb-5 my-select",
          class: {
            'my-select-dark': _vm.$vuetify.theme.dark
          },
          attrs: {
            "id": "select",
            "oulined": ""
          },
          on: {
            "change": function ($event) {
              var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
                return o.selected;
              }).map(function (o) {
                var val = "_value" in o ? o._value : o.value;
                return val;
              });
              _vm.$set(_vm.editItem, "code", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
            }
          }
        }, _vm._l(_vm.eventStatus, function (item, i) {
          return _c('optgroup', {
            key: i,
            attrs: {
              "label": item.text
            }
          }, _vm._l(item.children, function (opt, index) {
            return _c('option', {
              key: index,
              domProps: {
                "value": opt.code
              }
            }, [_vm._v(" " + _vm._s(opt.text) + " ")]);
          }), 0);
        }), 0) : _vm._e(), _vm.editItem.code.startsWith('refund') ? _c('ValidationProvider', {
          attrs: {
            "name": "refundAmount",
            "rules": "required|numeric"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref3) {
              var errors = _ref3.errors;
              return [_c('v-text-field', {
                attrs: {
                  "error-messages": errors,
                  "label": "Amount of refund",
                  "filled": "",
                  "type": "number"
                },
                model: {
                  value: _vm.editItem.refundAmount,
                  callback: function ($$v) {
                    _vm.$set(_vm.editItem, "refundAmount", _vm._n($$v));
                  },
                  expression: "editItem.refundAmount"
                }
              })];
            }
          }], null, true)
        }) : _vm._e(), _vm.editItem && _vm.editItem.code && _vm.editItem.code.startsWith('refund') ? _c('v-text-field', {
          attrs: {
            "error-messages": _vm.errors,
            "label": "Referenced Booking Number (special credit)",
            "filled": "",
            "type": "string"
          },
          model: {
            value: _vm.editItem.referencedBookingNumber,
            callback: function ($$v) {
              _vm.$set(_vm.editItem, "referencedBookingNumber", $$v);
            },
            expression: "editItem.referencedBookingNumber"
          }
        }) : _vm._e(), _c('v-textarea', {
          attrs: {
            "label": "Message",
            "outlined": ""
          },
          model: {
            value: _vm.editItem.message,
            callback: function ($$v) {
              _vm.$set(_vm.editItem, "message", $$v);
            },
            expression: "editItem.message"
          }
        }), _c('div', {
          staticClass: "d-flex justify-end"
        }, [_c('v-btn', {
          attrs: {
            "disabled": _vm.loading,
            "loading": _vm.loading,
            "color": "accent"
          },
          on: {
            "click": _vm.updateEvent
          }
        }, [_vm._v(" Save ")])], 1)];
      }
    }], null, false, 1276750263)
  })], 1)]], 2)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }